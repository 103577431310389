import Tile from '@components/Tile'
import cx from 'classnames'

export default function IndexPage() {
  return (
    <div
      className={cx(
        'container',
        'grid',
        'grid-cols-1',
        'gap-4',
        'md:grid-cols-2',
        'lg:grid-cols-3',
      )}
    >
      <Tile
        title="Axie Infinity"
        href="/axie-infinity"
        imageSrc="/images/axie-infinity/background.png"
      />
      <Tile
        title="Wanaka Farm"
        href="/wanaka-farm"
        imageSrc="/images/wanaka-farm/background.png"
      />
    </div>
  )
}
