import Link from 'next/link'
import Image from 'next/image'
import cx from 'classnames'

export default function Tile({
  className,
  href,
  imageSrc,
  title,
}: {
  className?: string
  href: string
  imageSrc: string
  title: string
}) {
  return (
    <Link href={href} passHref>
      <div
        className={cx(
          'cursor-pointer w-full relative rounded-lg overflow-hidden',
          className
        )}
      >
        <Image
          alt={title}
          className="w-full"
          height="337"
          layout="responsive"
          src={imageSrc}
          width="600"
        />
        <span className="absolute inset-0 flex justify-start items-end font-bold text-white p-4 bg-gradient-to-t from-black text-xl">
          {title}
        </span>
      </div>
    </Link>
  )
}
